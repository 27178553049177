import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7bb17c1a = () => interopDefault(import('..\\pages\\company\\aboutus.vue' /* webpackChunkName: "pages/company/aboutus" */))
const _27d2e774 = () => interopDefault(import('..\\pages\\company\\contactus.vue' /* webpackChunkName: "pages/company/contactus" */))
const _b69f2272 = () => interopDefault(import('..\\pages\\company\\gdpr.vue' /* webpackChunkName: "pages/company/gdpr" */))
const _4ee5ad7f = () => interopDefault(import('..\\pages\\company\\impressum.vue' /* webpackChunkName: "pages/company/impressum" */))
const _0a62bffe = () => interopDefault(import('..\\pages\\company\\jobs.vue' /* webpackChunkName: "pages/company/jobs" */))
const _0144d7f2 = () => interopDefault(import('..\\pages\\features\\for-enterprise.vue' /* webpackChunkName: "pages/features/for-enterprise" */))
const _943526d2 = () => interopDefault(import('..\\pages\\features\\headless-cms.vue' /* webpackChunkName: "pages/features/headless-cms" */))
const _2b2135ce = () => interopDefault(import('..\\pages\\features\\logging-and-monitoring.vue' /* webpackChunkName: "pages/features/logging-and-monitoring" */))
const _a0f34f5c = () => interopDefault(import('..\\pages\\features\\messaging.vue' /* webpackChunkName: "pages/features/messaging" */))
const _c3dc5914 = () => interopDefault(import('..\\pages\\features\\valuetracking.vue' /* webpackChunkName: "pages/features/valuetracking" */))
const _39dbd455 = () => interopDefault(import('..\\pages\\features\\why-to-use-konso.vue' /* webpackChunkName: "pages/features/why-to-use-konso" */))
const _6b94e88a = () => interopDefault(import('..\\pages\\jobs\\customer-support-manager.vue' /* webpackChunkName: "pages/jobs/customer-support-manager" */))
const _4c04f0f2 = () => interopDefault(import('..\\pages\\jobs\\talent-acquisition-specialist.vue' /* webpackChunkName: "pages/jobs/talent-acquisition-specialist" */))
const _7af73c86 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company/aboutus",
    component: _7bb17c1a,
    name: "company-aboutus"
  }, {
    path: "/company/contactus",
    component: _27d2e774,
    name: "company-contactus"
  }, {
    path: "/company/gdpr",
    component: _b69f2272,
    name: "company-gdpr"
  }, {
    path: "/company/impressum",
    component: _4ee5ad7f,
    name: "company-impressum"
  }, {
    path: "/company/jobs",
    component: _0a62bffe,
    name: "company-jobs"
  }, {
    path: "/features/for-enterprise",
    component: _0144d7f2,
    name: "features-for-enterprise"
  }, {
    path: "/features/headless-cms",
    component: _943526d2,
    name: "features-headless-cms"
  }, {
    path: "/features/logging-and-monitoring",
    component: _2b2135ce,
    name: "features-logging-and-monitoring"
  }, {
    path: "/features/messaging",
    component: _a0f34f5c,
    name: "features-messaging"
  }, {
    path: "/features/valuetracking",
    component: _c3dc5914,
    name: "features-valuetracking"
  }, {
    path: "/features/why-to-use-konso",
    component: _39dbd455,
    name: "features-why-to-use-konso"
  }, {
    path: "/jobs/customer-support-manager",
    component: _6b94e88a,
    name: "jobs-customer-support-manager"
  }, {
    path: "/jobs/talent-acquisition-specialist",
    component: _4c04f0f2,
    name: "jobs-talent-acquisition-specialist"
  }, {
    path: "/",
    component: _7af73c86,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
